import React from "react";
import { withRouter } from "./withRouter";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
// import Grid from "@mui/material/Grid";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ChatMapWeb from "../chatComponents/chatWebComponents/ChatMapWeb";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const APPLICATION_CODE = "ZN004";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class ChatWeb extends React.Component {
  constructor(props) {
    super(props);
    const profileImage = localStorage.getItem("profileImage");
    let parsedProfileImage = null;

    if (profileImage && profileImage !== "undefined") {
      try {
        parsedProfileImage = JSON.parse(profileImage);
      } catch (error) {
        console.error("Error parsing profile image from localStorage", error);
      }
    }
    this.fetchController = new AbortController();
    this.handleSendButton = this.handleSendButton.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.state = {
      value: 1,
      messagesWeb: JSON.parse(localStorage.getItem("messagesWeb"))
        ? JSON.parse(localStorage.getItem("messagesWeb"))
        : [],
      newMessage: "",
      loader: false,
      isPopperOpen: false,
      popperAnchorEl: null,
      bookmarked: false,
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      default_language_model: "Langchain",
      webData: [],
      pageNumber: 0,
      numPages: 0,
      showAllPages: {},
      prevQuestion: "",
      timeOutMsg: "",
      retryTime: 5,
      tryCount: 0,
      systemError: `System error. Please contact administrator`,
      loader_Messages: "",
      newMessage_1: "",
      langChainIndex: null,
      current_webs: props.current_webs || [],
      profilePicWeb: parsedProfileImage,
      assistantNameWeb: null,
      methodsCalled:localStorage.getItem("methodsCalled")?JSON.parse(localStorage.getItem("methodsCalled")):{},
      botImages:localStorage.getItem("botImages")?JSON.parse(localStorage.getItem("botImages")):{},
      profileDataFetched: false,
     

    };
    this.arrowRef = React.createRef();
    this.containerRef = React.createRef();
  }

  async componentDidMount() {
    const methodCalled={...this.state.methodsCalled}
    if (!methodCalled["WebgetProfileData"]) {
      await this.WebgetProfileData(); 
    }
    const botImage={...this.state.botImages}
    if (botImage.profilePicWeb || botImage.assistantNameWeb) {
      this.setState({
        profilePicWeb:botImage["profilePicWeb"],
        assistantNameWeb:botImage["assistantNameWeb"]
      })
    }
    
  }
  
  WebgetProfileData = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email")
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";

    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/customize_prompts_chat_ui/${userOrganization}?applicationType=${encodeURIComponent(
        "zunoforweb"
      )}&email=${encodeURIComponent(email)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        if (!result || result.message){
          this.setState(
            (prevState) => ({
              botImages: {
                ...prevState.botImages,
                profilePicWeb: null,
                assistantNameWeb: "Zuno",
              },
              profilePicWeb: null,
              assistantNameWeb: "Zuno",
            }),
            () => {
              // console.log("profilePicWeb", this.state.profilePicWeb);
              // console.log("botImages", this.state.botImages);
               localStorage.setItem("botImages", JSON.stringify(this.state.botImages))
            }
          );
        } else {
          this.setState(
            (prevState) => ({
              botImages: {
                ...prevState.botImages,
                profilePicWeb: result.profilePic,
                assistantNameWeb: result.assistantName
                  ? result.assistantName
                  : "Zuno",
              },
              profilePicWeb: result.profilePic,
              assistantNameWeb: result.assistantName
                ? result.assistantName
                : "Zuno",
            }),
            () => {
              // console.log("profilePicWeb", this.state.profilePicWeb);
              // console.log("botImages", this.state.botImages);
               localStorage.setItem("botImages", JSON.stringify(this.state.botImages))
            }
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    this.setState(prevState=>({
      methodsCalled:{...prevState.methodsCalled,WebgetProfileData:true}
    }),()=>{localStorage.setItem("methodsCalled",JSON.stringify(this.state.methodsCalled))})

  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleInputChange = (event) => {
    event.preventDefault();
    this.setState({
      newMessage: event.target.value,
      newMessage_1: event.target.value,
      langChainIndex: null,
    });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && event.shiftKey) {
      // Prevent Enter with Shift from creating a new line
      event.preventDefault();

      // Move the cursor to the next line
      const cursorPos = event.target.selectionStart;
      const inputValue = this.state.newMessage;
      const newMessage =
        inputValue.substring(0, cursorPos) +
        "\n" +
        inputValue.substring(cursorPos);

      this.setState({ newMessage });
    } else if (event.keyCode === 13) {
      // Prevent Enter from submitting the form
      event.preventDefault();

      // Handle sending the message
      if (!this.state.loader && !this.state.loader_llama) {
        this.handleSendButton(event);
      }
    }
  };
  generateTimestampBasedMessageId() {
    const timestamp = Date.now(); // Get current timestamp in milliseconds
    const randomDigits = Math.floor(Math.random() * 10000); // Generate random 4-digit number

    const messageId = `${timestamp}${randomDigits}`;

    return messageId;
  }

  handleRetry() {
    // event.preventDefault()
    let { messagesWeb } = this.state;

    messagesWeb = messagesWeb.slice(0, messagesWeb.length - 2);

    localStorage.setItem("messagesWeb", JSON.stringify(messagesWeb));

    this.setState(
      (prevState) => ({
        newMessage: prevState.prevQuestion
          ? prevState.prevQuestion
          : prevState.newMessage_1,
        messagesWeb: messagesWeb,
        tryCount: prevState.tryCount + 1,
      }),
      () => {
        // console.log("newMessage after retry", this.state.newMessage);
        // console.log("prevQuestion after retry", this.state.prevQuestion);
        // console.log("messageQA", this.state.messagesWeb);
        // console.log("try count", this.state.tryCount);
        this.handleLoadAndSendMessage();
      }
    );
  }

  async handleSendButton(event) {
    event.preventDefault();
    const { newMessage, messagesWeb, prevQuestion } = this.state;
    let error = "Connection timed out. Please try again";
    let langchainText;
    if (messagesWeb.length >= 1) {
      langchainText = messagesWeb[messagesWeb.length - 1].langchaintext
        ? messagesWeb[messagesWeb.length - 1].langchaintext
        : null;
    }

    if (newMessage === prevQuestion && error === langchainText) {
      this.handleRetry();
    }
    this.handleLoadAndSendMessage();
  }

  async handleLoadAndSendMessage(submitType) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const { default_language_model, newMessage, messagesWeb } = this.state;

    if (newMessage.trim() === "") {
      console.log("inside handleLoadReturn");
      return;
    }
    // Update selectedButtons state before sending the message
    let username = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    // const timestamp = new Date().toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const date = new Date();
    const timestamp = `${date.toLocaleDateString("en-US")} ${date
      .toLocaleTimeString("en-US")
      .slice(0, -3)}`;
    const currentMessageId = this.generateTimestampBasedMessageId();
    const newMessagesWeb = [
      ...messagesWeb,
      {
        queryID: currentMessageId,
        sender: username,
        text: newMessage,
        timestamp: timestamp,
      },
    ];
    this.setState({
      loader: true,
      messagesWeb: newMessagesWeb,
      prevQuestion: newMessage,
      newMessage: "",
      loader_Messages: "We're analyzing your document! ",
    });

    let count = 0;
    const loaderMessage = setInterval(() => {
      let messages = [
        "We're analyzing your document! ",
        "Extracting key information... ",
        "Unveiling insights from your document.",
        "Putting the pieces together to answer your question.",
        "Nearly there! Just a few more moments.",
        "Formulating the perfect response for you.",
        "Double-checking to ensure accuracy. ",
        "Getting close! You'll have your answer soon.",
        "Working on it...",
      ];

      this.setState({
        loader_Messages: messages[count],
      });
      count++;
    }, 30 * 1000); //30 seconds
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("username", username);
    formData.append("email", email);

    let model_endpoint;
    if (default_language_model === "Langchain") {
      model_endpoint = `${BASE_URL}/zn004/bot/post_web_QA_langchain`;
    }

    this.fetchController.abort();
    this.fetchController = new AbortController();

    const fetchTimeOut = setTimeout(() => {
      this.fetchController.abort();
    }, 4 * 60 * 1000); //4 minutes

    try {
      const response = await fetch(model_endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        signal: this.fetchController.signal,
      });

      clearTimeout(fetchTimeOut);
      if (response.status === 401) {
        this.props.navigate("/");
      }

      const result = await response.json();

      let receivedMessages;
      let responseMessage;
      if (default_language_model === "Langchain") {
        responseMessage = result.message;
        receivedMessages = [
          ...newMessagesWeb,
          {
            responseID: currentMessageId,
            sender: "ZUNO",
            langchaintext: result.message,
            llamatext: "",
            timestamp: timestamp,
          },
        ];
      }

      localStorage.setItem("messagesWeb", JSON.stringify(receivedMessages));

      // this.renderWeb(result, default_language_model);
      this.queryLogs(newMessage, responseMessage, result);
      this.setState({
        loader: false,
        messagesWeb: receivedMessages,
        prevQuestion: newMessage,
        // newMessage: "",
        value: 1,
      });
    } catch (error) {
      for (let index = 6; index >= 0; index--) {
        let arr = [5, 5, 4, 3, 2, 1];
        setTimeout(() => {
          this.setState({ retryTime: arr[index] });
        }, index * 1000);
      }

      //IIFE
      (() => {
        this.setState({ timeOutMsg: "Please wait for while" }, () => {
          // console.log("time out message IIFE", this.state.timeOutMsg);
        });
      })();

      let receivedMessages;
      let responseMessage;
      let errorMessage = "Connection timed out. Please try again";
      if (default_language_model === "Langchain") {
        responseMessage = errorMessage;
        receivedMessages = [
          ...newMessagesWeb,
          {
            responseID: currentMessageId,
            sender: "ZUNO",
            langchaintext: errorMessage,
            llamatext: "",
            timestamp: timestamp,
          },
        ];
      }
      localStorage.setItem("messagesWeb", JSON.stringify(receivedMessages));

      // this.renderWeb(errorMessage, default_language_model);
      this.queryLogs(newMessage, responseMessage, errorMessage);
      this.setState({
        //loader: false,
        messagesWeb: receivedMessages,
        prevQuestion: newMessage,
        newMessage: "",
        value: 1,
      });

      console.log("catch block ", error.message);
    } finally {
      this.setState({ loader: false });
      clearInterval(loaderMessage);
    }
  }

  renderWeb = async (result, language_model) => {
    try {
      const data = result.result_list ?? [];
      const uniqueCombinations = new Set();
      const uniqueResults = data?.filter((item) => {
        const combination = item.source + "-" + item.page;
        if (!uniqueCombinations.has(combination)) {
          uniqueCombinations.add(combination);
          return true;
        }
        return false;
      });

      const token = localStorage.getItem("token");

      const userOrganization =
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
          ?.organization ?? "";
      const uniqueEntries = new Set();

      const webData = await Promise.all(
        uniqueResults.map(async ({ source, page }) => {
          try {
            const web_path = this.getMatchingPath(source);
            const response = await fetch(
              `${BASE_URL}/zn004/bot/web?web_path=${encodeURIComponent(
                web_path
              )}&page_number=${encodeURIComponent(
                page
              )}&organization=${encodeURIComponent(
                userOrganization
              )}&application_code=${encodeURIComponent(
                APPLICATION_CODE
              )}&language_model=${encodeURIComponent(language_model)}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.ok) {
              const webBlob = await response.blob();
              const webName = source.split("\\").pop();
              const uniqueKey = `${webName}_${page}`;

              // Check if the combination of fileName and pageNumber is unique
              if (!uniqueEntries.has(uniqueKey)) {
                uniqueEntries.add(uniqueKey);
                return {
                  webUrl: URL.createObjectURL(webBlob),
                  webName,
                  pageNumber: page,
                };
              } else {
                console.log(
                  `Duplicate entry found for ${webName} and page ${page}. Skipping.`
                );
                return null;
              }
            } else {
              console.error(`Failed to fetch web data for ${source}`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching PDF data for ${source}:`, error);
            return null;
          }
        })
      );

      // Remove null entries from webData
      const filteredWebData = webData.filter((entry) => entry !== null);

      this.setState({ webData: filteredWebData });
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
  };

  getMatchingPath = (source) => {
    const { current_webs } = this.state;
    for (const web_path of current_webs) {
      if (web_path.endsWith(source)) {
        return web_path;
      }
    }
    return null;
  };
  queryLogs = async (query, responseMessage, response) => {
    console.log("response", response);
    const token = localStorage.getItem("token");
    const date = new Date().toLocaleTimeString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const userDetails = localStorage.getItem("currentLoggedInUserDetails");
    const userDetailsString = JSON.parse(userDetails);
    const department = userDetailsString.department;
    const organization = userDetailsString.organization;
    const queryLogsEndpoint = `${BASE_URL}/zn004/user/Query_logs`;
    const queryLogsFormData = new FormData();
    queryLogsFormData.append("query", query);
    queryLogsFormData.append("response", responseMessage);
    queryLogsFormData.append("date", date);
    queryLogsFormData.append("application", "WebDocument");
    queryLogsFormData.append("organization", organization);
    queryLogsFormData.append("department", department);
    queryLogsFormData.append("credentials", token);

    const token_consumed = response["token_consumed"]
      ? response["token_consumed"]
      : 0;
    const token_cost = response["token_cost"] ? response["token_cost"] : 0;
    queryLogsFormData.append("token_consumed", token_consumed);
    queryLogsFormData.append("token_cost", token_cost);
    try {
      const queryLogsResponse = await fetch(queryLogsEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: queryLogsFormData,
      });

      const queryLogsResult = await queryLogsResponse.json();
    } catch (error) {
      console.log(error);
    }
  };

  inputChange = (event) => {
    if (event.target.key === "Enter") {
      event.preventDefault();
    }
  };

  handlePopperOpen = (event) => {
    this.setState({
      isPopperOpen: true,
      popperAnchorEl: event.currentTarget,
    });
  };

  handlePopperClose = () => {
    this.setState({
      isPopperOpen: false,
      popperAnchorEl: null,
    });
  };

  handleFileUpload = () => {
    // Handle file upload logic
    this.handlePopperClose();
  };

  handleAddLink = () => {
    // Handle add link logic
    this.handlePopperClose();
  };

  toggleBookmark = async (e, index) => {
    e.preventDefault();
    const { messagesWeb } = this.state;
    const queryToBookmark = messagesWeb[index];
    const messageToBookmark = messagesWeb[index + 1];

    if (queryToBookmark.bookmarked) {
      const status = this.removeBookmark(queryToBookmark, index);
      if (status) {
        this.handleSnackbarOpen("success", "Removed bookmark successfully");
      } else {
        this.handleSnackbarOpen("error", "Removing bookmark failed");
      }
    } else {
      const status = this.createBookmark(
        messageToBookmark,
        queryToBookmark,
        index
      );
      if (status) {
        this.handleSnackbarOpen("success", "Created bookmark successfully");
      } else {
        this.handleSnackbarOpen("error", "Created bookmark failed");
      }
    }
  };
  createBookmark = async (messageToBookmark, queryToBookmark, messageIndex) => {
    const token = localStorage.getItem("token");
    const userDetails = localStorage.getItem("currentLoggedInUserDetails");
    const userDetailsString = JSON.parse(userDetails);

    const organization = userDetailsString.organization;
    try {
      const response = await fetch(`${BASE_URL}/zn004/user/bookmark`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          organization: organization,
          userQuery: queryToBookmark.text,
          response:
            this.state.default_language_model === "Langchain"
              ? messageToBookmark.langchaintext
              : "",
          timeStamp: messageToBookmark.timestamp,
        }),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json();

        // Assuming the server responds with a bookmark ID
        const bookmarkId = responseData.bookmark_id;
        //   // Update the state to reflect the bookmarked message
        this.setState(
          (prevState) => {
            // Create a copy of messages array to avoid modifying state directly
            const updatedMessages = [...prevState.messagesWeb];

            updatedMessages[messageIndex] = {
              ...queryToBookmark,
              bookmarked: true, // Update the bookmarked status
              bookmarkID: bookmarkId,
            };

            return { messagesWeb: updatedMessages };
          },
          () => {
            localStorage.setItem(
              "messagesWeb",
              JSON.stringify(this.state.messagesWeb)
            );
          }
        );
        return true;
      } else {
        console.error("Failed to toggle message bookmark");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  removeBookmark = async (queryToBookmark, messageIndex) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("token not found in localStorage");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const userDetails = localStorage.getItem("currentLoggedInUserDetails");
      const userDetailsString = JSON.parse(userDetails);

      const organization = userDetailsString.organization;
      const response = await fetch(
        `${BASE_URL}/zn004/user/remove_bookmark/${
          queryToBookmark.bookmarkID
        }?userOrganization=${encodeURIComponent(organization)}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        this.setState(
          (prevState) => {
            // Create a copy of messages array to avoid modifying state directly
            const updatedMessages = [...prevState.messagesWeb];

            updatedMessages[messageIndex] = {
              ...queryToBookmark,
              bookmarked: false, // Update the bookmarked status
              bookmarkID: null,
            };

            return { messagesWeb: updatedMessages };
          },
          () => {
            // Save the updated messages array to local storage after setState
            localStorage.setItem(
              "messagesWeb",
              JSON.stringify(this.state.messagesWeb)
            );
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  getProfileData = async () => {
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";

    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/customize_prompts/${userOrganization}?applicationType=${encodeURIComponent(
          "zunoforweb"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        this.setState({
          profilePicWeb: result.profilePic,
          assistantNameWeb: result.assistantName
            ? result.assistantName
            : "Zuno",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  handleSetTimeOutMsgWeb = () => {
    this.setState({ timeOutMsg: "" });
  };
  render() {
    const {
      messagesWeb,
      newMessage,
      value,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
      selectedButtons,
      timeOutMsg,
      retryTime,
      tryCount,
      systemError,
      loader_Messages,
      loader,
      langChainIndex,
      profilePicWeb,
      assistantNameWeb,
    } = this.state;

    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "2%",
            flexDirection: "column",
            // border: "1px solid black",
          }}
          // ref={this.containerRef}
          // style={{
          //   height: "calc(95vh - 200px)",
          //   overflow: "auto",
          //   padding: "10px 10px 10px 10px",
          //   marginTop: "5%",
          //   width: "95%",
          //   background: "#F3F6FD",
          //   borderRadius: "20px 20px 0px 0px",
          //   fontSize: "30px",
          // }}
        >
          <TabPanel value={value} index={1}>
            <ChatMapWeb
              messagesWeb={messagesWeb}
              selectedButtons={selectedButtons}
              loader={loader}
              langChainIndex={langChainIndex}
              loader_Messages={loader_Messages}
              systemError={systemError}
              timeOutMsg={timeOutMsg}
              retryTime={retryTime}
              tryCount={tryCount}
              newMessage={newMessage}
              handleInputChange={this.handleInputChange}
              handleKeyDown={this.handleKeyDown}
              handleSendButton={this.handleSendButton}
              handleRetry={this.handleRetry}
              handleSendMessagelangchain={this.handleSendMessagelangchain}
              toggleBookmark={this.toggleBookmark}
              profilePicWeb={profilePicWeb}
              assistantNameWeb={assistantNameWeb}
              handleSetTimeOutMsgWeb={this.handleSetTimeOutMsgWeb}
            />
          </TabPanel>
        </Box>

        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}
export default withRouter(ChatWeb);
