import React from "react";
import { withRouter } from "./withRouter";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
//import ChatMap from "..chatComponents/ChatMap";
import ChatMap from "../chatComponents/ChatMap";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const APPLICATION_CODE = "ZN002";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}
class pdfchat extends React.Component {
  constructor(props) {
    super(props);
    this.fetchController = new AbortController();
    this.handleSendButton = this.handleSendButton.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.handleRetry_Llama = this.handleRetry_Llama.bind(this);
    this.state = {
      value: 1,
      messagesQA: JSON.parse(localStorage.getItem("messagesQA"))
        ? JSON.parse(localStorage.getItem("messagesQA"))
        : [],
      newMessage: "",
      loader: false,
      loader_llama: false,
      isPopperOpen: false,
      popperAnchorEl: null,
      bookmarked: false,
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      selectedButton: "Langchain",
      selectedButtons: JSON.parse(localStorage.getItem("selectedButtons"))
        ? JSON.parse(localStorage.getItem("selectedButtons"))
        : {},
      default_language_model: "Langchain",
      fileData: [],
      pageNumber: 0,
      numPages: 0,
      showAllPages: {},
      prevQuestion: "",
      timeOutMsg: "",
      retryTime: 5,
      tryCount: 0,
      timeOut_llma: "",
      retryTime_llma: 5,
      tryCount_llama: 0,
      systemError: `System error. Please contact administrator`,
      loader_Messages: "",
      newMessage_1: "",
      llamaIndex: 0,
      langChainIndex: null,
      current_files: props.current_files || [],
      profilePicDocs: null,
      assistantNameDocs: null,
      methodsCalled:localStorage.getItem("methodsCalled")?JSON.parse(localStorage.getItem("methodsCalled")):{},
      botImages:localStorage.getItem("botImages")?JSON.parse(localStorage.getItem("botImages")):{},

      selectedLanguageModels: { Langchain: true, Llamaindex: true },
      profileDataFetched: false,
    };
    this.arrowRef = React.createRef();
    this.containerRef = React.createRef();
  }
  async componentDidMount() {
 
    const methodCalled={...this.state.methodsCalled}
   

    await this.getSelectedModels();

    if (!methodCalled["DocsgetProfileData"]) {
      await this.DocsgetProfileData();
    }
    const botImage={...this.state.botImages}
    if (botImage.profilePicDocs || botImage.assistantNameDocs) {
      this.setState({
        profilePicDocs:botImage["profilePicDocs"],
        assistantNameDocs:botImage["assistantNameDocs"]
      },()=>{
        // console.log("222profilePicDocs",this.state.profilePicDocs);
        // console.log("222assistantNameDocs",this.state.assistantNameDocs);
      })
    }

  }
  DocsgetProfileData = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    try {
      const response = await fetch(
       `${BASE_URL}/admin/customize_prompts_chat_ui/${userOrganization}?applicationType=${encodeURIComponent(
        "zunofordocs"
      )}&email=${encodeURIComponent(email)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        if (!result || result.message){
          this.setState(
            (prevState) => ({
              botImages: {
                ...prevState.botImages,
                profilePicDocs: null,
                assistantNameDocs: "Zuno",
              },
              profilePicDocs: null,
              assistantNameDocs: "Zuno",
            }),
            () => {
              //  console.log("botImagesDocs", this.state.botImages);
               localStorage.setItem("botImages", JSON.stringify(this.state.botImages))
            }
          );
        }
        else {
          this.setState(
            (prevState) => ({
              botImages: {
                ...prevState.botImages,
                profilePicDocs: result.profilePic,
                assistantNameDocs: result.assistantName
                  ? result.assistantName
                  : "Zuno",
              },
              profilePicDocs:result.profilePic,
              assistantNameDocs: result.assistantName
              ? result.assistantName
              : "Zuno",
            }),
            () => {
              //  console.log("botImagesDocs", this.state.botImages);
               localStorage.setItem("botImages", JSON.stringify(this.state.botImages))
            }
          );
        }
        
      }
    } catch (error) {
      console.log("error", error);
    }
    this.setState(prevState=>({
      methodsCalled:{...prevState.methodsCalled,DocsgetProfileData:true}
    }),()=>{

      localStorage.setItem("methodsCalled",JSON.stringify(this.state.methodsCalled))
    })

  };
  toggleShowAllPages = (fileName) => {
    this.setState((prevState) => ({
      showAllPages: {
        ...prevState.showAllPages,
        [fileName]: !prevState.showAllPages[fileName],
      },
    }));
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleInputChange = (event) => {
    event.preventDefault();
    this.setState({
      newMessage: event.target.value,
      newMessage_1: event.target.value,
      langChainIndex: null,
    });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && event.shiftKey) {
      // Prevent Enter with Shift from creating a new line
      event.preventDefault();

      // Move the cursor to the next line
      const cursorPos = event.target.selectionStart;
      const inputValue = this.state.newMessage;
      const newMessage =
        inputValue.substring(0, cursorPos) +
        "\n" +
        inputValue.substring(cursorPos);

      this.setState({ newMessage });
    } else if (event.keyCode === 13) {
      // Prevent Enter from submitting the form
      event.preventDefault();

      // Handle sending the message
      if (!this.state.loader && !this.state.loader_llama) {
        this.handleSendButton(event);
      }
    }
  };
  generateTimestampBasedMessageId() {
    const timestamp = Date.now(); // Get current timestamp in milliseconds
    const randomDigits = Math.floor(Math.random() * 10000); // Generate random 4-digit number

    const messageId = `${timestamp}${randomDigits}`;

    return messageId;
  }

  handleRetry() {
    // event.preventDefault()
    let { messagesQA } = this.state;

    messagesQA = messagesQA.slice(0, messagesQA.length - 2);

    localStorage.setItem("messagesQA", JSON.stringify(messagesQA));

    this.setState(
      (prevState) => ({
        newMessage: prevState.prevQuestion
          ? prevState.prevQuestion
          : prevState.newMessage_1,
        messagesQA: messagesQA,
        tryCount: prevState.tryCount + 1,
      }),
      () => {
        // console.log("newMessage after retry", this.state.newMessage);
        // console.log("prevQuestion after retry", this.state.prevQuestion);
        // console.log("messageQA", this.state.messagesQA);
        // console.log("try count", this.state.tryCount);
        this.handleLoadAndSendMessage();
      }
    );
  }

  async handleRetry_Llama(event, index, message) {
    this.setState(
      (prevState) => ({
        newMessage: prevState.prevQuestion,
        messagesQA: prevState.messagesQA,
        tryCount_llama: prevState.tryCount_llama + 1,
        langChainIndex: null,
      }),
      () => {
        // console.log("retry llma messageQA", messagesQA);
        // console.log("newMessage after retry", this.state.newMessage);
        // console.log("prevQuestion after retry", this.state.prevQuestion);
        // console.log("retry count llama", this.state.tryCount_llama);
        this.handleSendMessagellamaindex(event, index, message);
      }
    );
  }

  async handleSendButton(event) {
    event.preventDefault();
    const { newMessage, messagesQA, prevQuestion } = this.state;
    let error = "Connection timed out. Please try again";
    let langchainText;
    if (messagesQA.length >= 1) {
      langchainText = messagesQA[messagesQA.length - 1].langchaintext
        ? messagesQA[messagesQA.length - 1].langchaintext
        : null;
    }

    if (newMessage === prevQuestion && error === langchainText) {
      this.handleRetry();
    }
    // else if(newMessage === prevQuestion && error === llmaText){
    //   this.handleRetry_Llama()
    // }
    else {
      this.setState((prevState) => ({
        tryCount: (prevState.tryCount = 0),
        tryCount_llama: (prevState.tryCount_llama = 0),
      }));
      this.handleLoadAndSendMessage();
    }
  }

  async handleLoadAndSendMessage(submitType) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    const { default_language_model, newMessage, messagesQA } = this.state;

    if (newMessage.trim() === "") {
      console.log("inside handleLoadReturn");
      return;
    }

    // Update selectedButtons state before sending the message
    let username = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    // const timestamp = new Date().toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const date = new Date();
    const timestamp = `${date.toLocaleDateString("en-US")} ${date
      .toLocaleTimeString("en-US")
      .slice(0, -3)}`;
    const currentMessageId = this.generateTimestampBasedMessageId();
    const newSelectedButtons = {
      ...this.state.selectedButtons,
      [currentMessageId]: default_language_model,
    };
    const newMessagesQA = [
      ...messagesQA,
      {
        queryID: currentMessageId,
        sender: username,
        text: newMessage,
        timestamp: timestamp,
      },
    ];
    this.setState({
      loader: true,
      selectedButtons: newSelectedButtons,
      messagesQA: newMessagesQA,
      prevQuestion: newMessage,
      newMessage: "",
      loader_Messages: "We're analyzing your document! ",
    });
    let count = 0;
    const loaderMessage = setInterval(() => {
      let messages = [
        "We're analyzing your document! ",
        "Extracting key information... ",
        "Unveiling insights from your document.",
        "Putting the pieces together to answer your question.",
        "Nearly there! Just a few more moments.",
        "Formulating the perfect response for you.",
        "Double-checking to ensure accuracy. ",
        "Getting close! You'll have your answer soon.",
        "Working on it...",
      ];

      this.setState({
        loader_Messages: messages[count],
      });
      count++;
    }, 30 * 1000); //30 seconds
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("username", username);
    formData.append("email", email);

    let model_endpoint;
    if (default_language_model === "Langchain") {
      model_endpoint = `${BASE_URL}/zn002/bot/post_QA_langchain`;
    } else if (default_language_model === "Llamaindex") {
      model_endpoint = `${BASE_URL}/zn002/bot/post_QA_llamaindex`;
    }

    this.fetchController.abort();
    this.fetchController = new AbortController();

    const fetchTimeOut = setTimeout(() => {
      this.fetchController.abort();
    }, 4 * 60 * 1000); //4 minutes

    try {
      const response = await fetch(model_endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        signal: this.fetchController.signal,
      });

      clearTimeout(fetchTimeOut);
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }

      const result = await response.json();

      let receivedMessages;
      let responseMessage;
      if (default_language_model === "Langchain") {
        responseMessage = result.message;
        receivedMessages = [
          ...newMessagesQA,
          {
            responseID: currentMessageId,
            sender: "ZUNO",
            langchaintext: result.message,
            llamatext: "",
            timestamp: timestamp,
          },
        ];
      } else if (default_language_model === "Llamaindex") {
        responseMessage = result.message.response
          ? result.message.response
          : result.message;
        receivedMessages = [
          ...newMessagesQA,
          {
            responseID: currentMessageId,
            sender: "ZUNO",
            langchaintext: "",
            llamatext: result.message,
            timestamp: timestamp,
          },
        ];
      }

      localStorage.setItem("messagesQA", JSON.stringify(receivedMessages));
      localStorage.setItem(
        "selectedButtons",
        JSON.stringify(newSelectedButtons)
      );

      this.renderPdf(result, default_language_model);
      this.queryLogs(newMessage, responseMessage, result);
      this.setState({
        loader: false,
        messagesQA: receivedMessages,
        prevQuestion: newMessage,
        // newMessage: "",
        value: 1,
      });
    } catch (error) {
      for (let index = 6; index >= 0; index--) {
        let arr = [5, 5, 4, 3, 2, 1];
        setTimeout(() => {
          this.setState({ retryTime: arr[index] });
        }, index * 1000);
      }

      //IIFE
      (() => {
        this.setState({ timeOutMsg: "Please wait for while" }, () => {
          // console.log("time out message IIFE", this.state.timeOutMsg);
        });
      })();

      let receivedMessages;
      let responseMessage;
      let errorMessage = "Connection timed out. Please try again";
      if (default_language_model === "Langchain") {
        responseMessage = errorMessage;
        receivedMessages = [
          ...newMessagesQA,
          {
            responseID: currentMessageId,
            sender: "ZUNO",
            langchaintext: errorMessage,
            llamatext: "",
            timestamp: timestamp,
          },
        ];
      } else if (default_language_model === "Llamaindex") {
        console.log(error);
        responseMessage = errorMessage;

        receivedMessages = [
          ...newMessagesQA,
          {
            responseID: currentMessageId,
            sender: "ZUNO",
            langchaintext: "",
            llamatext: errorMessage,
            timestamp: timestamp,
          },
        ];
      }
      localStorage.setItem("messagesQA", JSON.stringify(receivedMessages));
      localStorage.setItem(
        "selectedButtons",
        JSON.stringify(newSelectedButtons)
      );

      this.renderPdf(errorMessage, default_language_model);
      this.queryLogs(newMessage, responseMessage, errorMessage);
      this.setState({
        //loader: false,
        messagesQA: receivedMessages,
        prevQuestion: newMessage,
        newMessage: "",
        value: 1,
      });

      console.log("catch block ", error.message);
    } finally {
      this.setState({ loader: false });
      clearInterval(loaderMessage);
    }
  }

  handleSendMessagelangchain = async (event, index, message) => {
    event.preventDefault();
    let { newMessage, loader_Messages, newMessage_1 } = this.state;
    this.setState({
      langChainIndex: index,
      prevQuestion: newMessage_1,
    });
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    this.setState((prevState) => {
      const selectedButtons = { ...prevState.selectedButtons };
      selectedButtons[message.responseID] = "Langchain";
      localStorage.setItem("selectedButtons", JSON.stringify(selectedButtons));
      return { selectedButtons };
    });

    if (message.langchaintext) {
      this.setState({ langChainIndex: null });
      console.log("inside langchain return");
      return;
    }

    const currentMessagesQA = this.state.messagesQA;
    let username = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    const queryIndex = currentMessagesQA.findIndex(
      (item) => item.queryID === message.responseID
    );
    const query = queryIndex !== -1 ? currentMessagesQA[queryIndex].text : "";
    const formData = new FormData();
    formData.append("message", query);
    formData.append("username", username);
    formData.append("email", email);
    this.setState({
      loader: true,
      loader_Messages: "We're analyzing your document! ",
    });
    let count = 0;
    const loaderMessage = setInterval(() => {
      let messages = [
        "We're analyzing your document! ",
        "Extracting key information... ",
        "Unveiling insights from your document.",
        "Putting the pieces together to answer your question.",
        "Nearly there! Just a few more moments.",
        "Formulating the perfect response for you.",
        "Double-checking to ensure accuracy. ",
        "Getting close! You'll have your answer soon.",
        "Working on it...",
      ];

      this.setState({
        loader_Messages: messages[count],
      });
      count++;
    }, 30 * 1000); //30 seconds

    try {
      const response = await fetch(`${BASE_URL}/zn002/bot/post_QA_langchain`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const result = await response.json();
      const responseIndex = currentMessagesQA.findIndex(
        (item) => item.responseID === message.responseID
      );
      if (responseIndex !== -1) {
        // Update llamaindex with newMessage
        currentMessagesQA[responseIndex].langchaintext = result.message;
      }
      localStorage.setItem("messagesQA", JSON.stringify(currentMessagesQA));
      this.renderPdf(result, "Langchain");
      this.queryLogs(query, result.message, result);

      this.setState({
        loader: false,
        messagesQA: currentMessagesQA,
        prevQuestion: newMessage,
        value: 1,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loader: false });
      clearInterval(loaderMessage);
    }
  };

  handleSendMessagellamaindex = async (event, index, message) => {
    event.preventDefault();
    const { newMessage } = this.state;
    this.setState({ llamaIndex: index });
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    this.setState((prevState) => {
      const selectedButtons = { ...prevState.selectedButtons };
      selectedButtons[message.responseID] = "Llamaindex";

      localStorage.setItem("selectedButtons", JSON.stringify(selectedButtons));
      return { selectedButtons };
    });

    let errorMessage = "Connection timed out. Please try again";
    if (message.llamatext !== errorMessage && message.llamatext) {
      console.log("llama text inside return statement", message.llamatext);
      return;
    }
    //message.llamatext=''
    const currentMessagesQA = this.state.messagesQA;
    // this.setState({ loader: true });
    let username = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    const queryIndex = currentMessagesQA.findIndex(
      (item) => item.queryID === message.responseID
    );
    const query = queryIndex !== -1 ? currentMessagesQA[queryIndex].text : " ";
    const formData = new FormData();
    formData.append("message", query);
    formData.append("username", username);
    formData.append("email", email);

    this.setState({
      loader_llama: true,
      messagesQA: currentMessagesQA,
      prevQuestion: newMessage,
      // newMessage: "",
      loader_Messages: "We're analyzing your document! ",
    });

    let count = 0;
    const loaderMessages_llama = setInterval(() => {
      let messages = [
        "We're analyzing your document! ",
        "Extracting key information... ",
        "Unveiling insights from your document.",
        "Putting the pieces together to answer your question.",
        "Nearly there! Just a few more moments.",
        "Formulating the perfect response for you.",
        "Double-checking to ensure accuracy. ",
        "Getting close! You'll have your answer soon.",
        "Working on it...",
      ];
      this.setState({
        loader_Messages: messages[count],
      });
      count++;
    }, 30 * 1000); //30 seconds

    this.fetchController.abort();
    this.fetchController = new AbortController();

    const fetchTimeOut = setTimeout(() => {
      this.fetchController.abort();
    }, 4 * 60 * 1000); // 4 minutes
    try {
      const response = await fetch(`${BASE_URL}/zn002/bot/post_QA_llamaindex`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        signal: this.fetchController.signal,
      });

      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }

      clearTimeout(fetchTimeOut);

      const result = await response.json();
      const responseIndex = currentMessagesQA.findIndex(
        (item) => item.responseID === message.responseID
      );
      if (responseIndex !== -1) {
        // Update llamaindex with newMessage
        currentMessagesQA[responseIndex].llamatext = result.message;
      }
      localStorage.setItem("messagesQA", JSON.stringify(currentMessagesQA));
      // localStorage.setItem('selectedButtons',JSON.stringify(this.state.selectedButtons))
      const responseMessage = result.message.response
        ? result.message.response
        : result.message;

      this.renderPdf(result, "Llamaindex");
      this.queryLogs(query, responseMessage, result);
      this.setState({
        loader_llama: false,
        messagesQA: currentMessagesQA,
        prevQuestion: newMessage,
        // newMessage:"",
        value: 1,
      });
    } catch (error) {
      console.log("catch block llma", error);
      let errorMessage = "Connection timed out. Please try again";
      for (let index = 6; index >= 0; index--) {
        let arr = [5, 5, 4, 3, 2, 1];
        setTimeout(() => {
          this.setState({ retryTime_llma: arr[index] });
        }, index * 1000);
      }

      //IIFE
      (() => {
        this.setState({ timeOut_llma: "Please wait for while" }, () => {});
      })();
      const responseIndex = currentMessagesQA.findIndex(
        (item) => item.responseID === message.responseID
      );
      if (responseIndex !== -1) {
        // Update llamaindex with newMessage
        currentMessagesQA[responseIndex].llamatext = errorMessage;
      }
      localStorage.setItem("messagesQA", JSON.stringify(currentMessagesQA));
      // localStorage.setItem('selectedButtons',JSON.stringify(this.state.selectedButtons))
      const responseMessage = errorMessage ? errorMessage : errorMessage;

      this.renderPdf(errorMessage, "Llamaindex");
      this.queryLogs(query, responseMessage, errorMessage);
      this.setState({
        // loader_llama: false,
        messagesQA: currentMessagesQA,
        prevQuestion: newMessage,
        newMessage: "",
        value: 1,
      });
      console.log(error);
    } finally {
      this.setState({ loader_llama: false });
      clearInterval(loaderMessages_llama);
    }
  };
  renderPdf = async (result, language_model) => {
    try {
      const data = result.result_list ?? [];
      const uniqueCombinations = new Set();
      const uniqueResults = data?.filter((item) => {
        const combination = item.source + "-" + item.page;
        if (!uniqueCombinations.has(combination)) {
          uniqueCombinations.add(combination);
          return true;
        }
        return false;
      });

      const token = localStorage.getItem("token");

      const userOrganization =
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
          ?.organization ?? "";
      const uniqueEntries = new Set();

      const fileData = await Promise.all(
        uniqueResults.map(async ({ source, page }) => {
          try {
            const pdf_path = this.getMatchingPath(source);
            const response = await fetch(
              `${BASE_URL}/zn002/bot/pdf?pdf_path=${encodeURIComponent(
                pdf_path
              )}&page_number=${encodeURIComponent(
                page
              )}&organization=${encodeURIComponent(
                userOrganization
              )}&application_code=${encodeURIComponent(
                APPLICATION_CODE
              )}&language_model=${encodeURIComponent(language_model)}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.ok) {
              const pdfBlob = await response.blob();
              const fileName = source.split("\\").pop();
              const uniqueKey = `${fileName}_${page}`;

              // Check if the combination of fileName and pageNumber is unique
              if (!uniqueEntries.has(uniqueKey)) {
                uniqueEntries.add(uniqueKey);
                return {
                  fileUrl: URL.createObjectURL(pdfBlob),
                  fileName,
                  pageNumber: page,
                };
              } else {
                console.log(
                  `Duplicate entry found for ${fileName} and page ${page}. Skipping.`
                );
                return null;
              }
            } else {
              console.error(`Failed to fetch PDF data for ${source}`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching PDF data for ${source}:`, error);
            return null;
          }
        })
      );

      // Remove null entries from fileData
      const filteredFileData = fileData.filter((entry) => entry !== null);

      this.setState({ fileData: filteredFileData });
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
  };

  getMatchingPath = (source) => {
    const { current_files } = this.state;
    // If source contains a file path, extract the filename
    const sourceFilename = source.includes("/")
      ? source.split("/").pop()
      : source;

    for (const file_path of current_files) {
      // Extract the filename from the file path
      const filename = file_path.split("/").pop();
      if (filename === sourceFilename) {
        return file_path;
      }
    }
    return null;
  };
  queryLogs = async (query, responseMessage, response) => {
    console.log("response", response);
    const token = localStorage.getItem("token");
    const date = new Date().toLocaleTimeString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const userDetails = localStorage.getItem("currentLoggedInUserDetails");
    const userDetailsString = JSON.parse(userDetails);
    const department = userDetailsString.department;
    const organization = userDetailsString.organization;
    const queryLogsEndpoint = `${BASE_URL}/zn002/user/Query_logs`;
    const queryLogsFormData = new FormData();
    queryLogsFormData.append("query", query);
    queryLogsFormData.append("response", responseMessage);
    queryLogsFormData.append("date", date);
    queryLogsFormData.append("application", "Document");
    queryLogsFormData.append("organization", organization);
    queryLogsFormData.append("department", department);
    queryLogsFormData.append("credentials", token);

    const token_consumed = response["token_consumed"]
      ? response["token_consumed"]
      : 0;
    const token_cost = response["token_cost"] ? response["token_cost"] : 0;
    queryLogsFormData.append("token_consumed", token_consumed);
    queryLogsFormData.append("token_cost", token_cost);

    try {
      const queryLogsResponse = await fetch(queryLogsEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: queryLogsFormData,
      });

      const queryLogsResult = await queryLogsResponse.json();
    } catch (error) {
      console.log(error);
    }
  };

  inputChange = (event) => {
    if (event.target.key === "Enter") {
      event.preventDefault();
    }
  };

  handlePopperOpen = (event) => {
    this.setState({
      isPopperOpen: true,
      popperAnchorEl: event.currentTarget,
    });
  };

  handlePopperClose = () => {
    this.setState({
      isPopperOpen: false,
      popperAnchorEl: null,
    });
  };

  handleFileUpload = () => {
    // Handle file upload logic
    this.handlePopperClose();
  };

  handleAddLink = () => {
    // Handle add link logic
    this.handlePopperClose();
  };

  toggleBookmark = async (e, index) => {
    e.preventDefault();
    const { messagesQA } = this.state;

    const queryToBookmark = messagesQA[index];
    const messageToBookmark = messagesQA[index + 1];

    if (queryToBookmark.bookmarked) {
      const status = this.removeBookmark(queryToBookmark, index);
      if (status) {
        this.handleSnackbarOpen("success", "Removed bookmark successfully");
      } else {
        this.handleSnackbarOpen("error", "Removing bookmark failed");
      }
    } else {
      const status = this.createBookmark(
        messageToBookmark,
        queryToBookmark,
        index
      );
      if (status) {
        this.handleSnackbarOpen("success", "Created bookmark successfully");
      } else {
        this.handleSnackbarOpen("error", "Created bookmark failed");
      }
    }
  };
  createBookmark = async (messageToBookmark, queryToBookmark, messageIndex) => {
    const token = localStorage.getItem("token");
    const userDetails = localStorage.getItem("currentLoggedInUserDetails");
    const userDetailsString = JSON.parse(userDetails);

    const organization = userDetailsString.organization;
    try {
      const response = await fetch(`${BASE_URL}/zn002/user/bookmark`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          organization: organization,
          userQuery: queryToBookmark.text,
          response:
            this.state.default_language_model === "Langchain"
              ? messageToBookmark.langchaintext
              : messageToBookmark.llamatext.response,
          timeStamp: messageToBookmark.timestamp,
        }),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json();
        // Assuming the server responds with a bookmark ID
        const bookmarkId = responseData.bookmark_id;
        //   // Update the state to reflect the bookmarked message
        this.setState(
          (prevState) => {
            // Create a copy of messages array to avoid modifying state directly
            const updatedMessages = [...prevState.messagesQA];

            updatedMessages[messageIndex] = {
              ...queryToBookmark,
              bookmarked: true, // Update the bookmarked status
              bookmarkID: bookmarkId,
            };

            return { messagesQA: updatedMessages };
          },
          () => {
            localStorage.setItem(
              "messagesQA",
              JSON.stringify(this.state.messagesQA)
            );
          }
        );
        return true;
      } else {
        console.error("Failed to toggle message bookmark");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  removeBookmark = async (queryToBookmark, messageIndex) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("token not found in localStorage");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const userDetails = localStorage.getItem("currentLoggedInUserDetails");
      const userDetailsString = JSON.parse(userDetails);

      const organization = userDetailsString.organization;
      const response = await fetch(
        `${BASE_URL}/zn002/user/remove_bookmark/${
          queryToBookmark.bookmarkID
        }?userOrganization=${encodeURIComponent(organization)}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        this.setState(
          (prevState) => {
            // Create a copy of messages array to avoid modifying state directly
            const updatedMessages = [...prevState.messagesQA];

            updatedMessages[messageIndex] = {
              ...queryToBookmark,
              bookmarked: false, // Update the bookmarked status
              bookmarkID: null,
            };

            return { messagesQA: updatedMessages };
          },
          () => {
            // Save the updated messages array to local storage after setState
            localStorage.setItem(
              "messagesQA",
              JSON.stringify(this.state.messagesQA)
            );
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  getProfileData = async () => {
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/customize_prompts/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordocs"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        // console.log("result",result);
        if (result.message){
          this.setState({
            profilePicDocs: null,
            assistantNameDocs: "Zuno",
          });
        }
        else {
          this.setState({
            profilePicDocs: result.profilePic,
            assistantNameDocs: result.assistantName
              ? result.assistantName
              : "Zuno",
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  handleSetTimeOutMsgDocs = () => {
    this.setState({ timeOutMsg: "", timeOut_llma: "" });
  };

  getSelectedModels = async () => {
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/get_selected_language_models/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordocs"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        let selected_language_models, selected_button, default_language_model;
        if (result) {
          selected_language_models = result.selected_models;

          if (selected_language_models.Langchain) {
            selected_button = "Langchain";
            default_language_model = "Langchain";
          } else if (selected_language_models.Llamaindex) {
            selected_button = "Llamaindex";
            default_language_model = "Llamaindex";
          } else {
            selected_button = "Langchain";
            default_language_model = "Langchain";
          }
        }
        this.setState({
          selectedLanguageModels: selected_language_models,
          default_language_model: default_language_model,
          selectedButton: selected_button,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const {
      fileData,
      messagesQA,
      newMessage,
      value,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
      selectedButtons,
      timeOutMsg,
      retryTime,
      tryCount,
      timeOut_llma,
      retryTime_llma,
      tryCount_llama,
      systemError,
      loader_Messages,
      loader,
      loader_llama,
      llamaIndex,
      langChainIndex,
      profilePicDocs,
      assistantNameDocs,
      selectedLanguageModels,
    } = this.state;
    const {filenames,summary,filenameAndSummary}=this.props
    // console.log("filenameAndSummary",filenameAndSummary);
    console.log("assistantNameDocs",assistantNameDocs);
    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "2%",
            flexDirection: "column",
            // border: "1px solid black",
          }}
        >
          <AppBar
            position="static"
            color="default"
            sx={{
              backgroundColor: "#F3F6FD",
              borderRadius: "10px",
              width: "95%",
              // marginLeft: "5%",
            }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              textColor="inherit"
              aria-label="disabled tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#6001FF",
                },
                "& .MuiTab-root": {
                  color: "#6001FF",
                },
                marginLeft: "1%",
              }}
            >
              <Tab label="Resources" {...a11yProps(0)} />
              <Tab label="Chat" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          {/* <PdfChatAppBar value={value} handleChange={this.handleChange} a11yProps={a11yProps}/> */}
          <TabPanel value={value} index={0}>
            {fileData.map((file, index) => (
              <div key={index}>
                <embed
                  src={file.fileUrl}
                  type="application/pdf"
                  width="95%"
                  height="600px"
                />
              </div>
            ))}
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ChatMap
              messagesQA={messagesQA}
              selectedButtons={selectedButtons}
              loader={loader}
              loader_llama={loader_llama}
              langChainIndex={langChainIndex}
              llamaIndex={llamaIndex}
              loader_Messages={loader_Messages}
              systemError={systemError}
              tryCount_llama={tryCount_llama}
              timeOut_llma={timeOut_llma}
              retryTime_llma={retryTime_llma}
              timeOutMsg={timeOutMsg}
              retryTime={retryTime}
              tryCount={tryCount}
              newMessage={newMessage}
              selectedLanguageModels={selectedLanguageModels}
              handleInputChange={this.handleInputChange}
              handleKeyDown={this.handleKeyDown}
              handleSendButton={this.handleSendButton}
              handleRetry={this.handleRetry}
              handleRetry_Llama={this.handleRetry_Llama}
              handleSendMessagellamaindex={this.handleSendMessagellamaindex}
              handleSendMessagelangchain={this.handleSendMessagelangchain}
              toggleBookmark={this.toggleBookmark}
              profilePicDocs={profilePicDocs}
              assistantNameDocs={assistantNameDocs}
              handleSetTimeOutMsgDocs={this.handleSetTimeOutMsgDocs}
              filenames={filenames}
              summary={summary}
              filenameAndSummary={filenameAndSummary}
            />
          </TabPanel>
        </Box>

        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}
export default withRouter(pdfchat);
