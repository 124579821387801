import React from "react";
import Grid from "@mui/material/Grid";
import { withRouter } from "./withRouter";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import HeaderText from "./chatComponents/HeaderText";
import ChatMapData from "./chatComponents/ChatMapData";
import ChatInputBase from "./chatComponents/ChatInputBase";
import LoaderMessages from "./chatComponents/LoaderMessages";
import Utils from "../userComponents/Utils";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// import { text } from 'd3';
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const application_code = "ZN001";
class ChatUI extends React.Component {
  constructor(props) {
    super(props);
    this.fetchController = new AbortController();
    this.handleSendButton = this.handleSendButton.bind(this);
    this.state = {
      messages: JSON.parse(localStorage.getItem("messages"))
        ? JSON.parse(localStorage.getItem("messages"))
        : [],
      bookmarkedMessages:
        JSON.parse(localStorage.getItem("bookmarkedMessages")) || [], // Initialize bookmarked messages from localStorage

      newMessage: "",
      viewQuery: false,
      bookmarked: false,
      isBookmarked: false,
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      selectedBookmark: null,
      isTableVisible: true,
      isChartVisible: false,
      prevQuestion: "",
      timeOutMsg: "",
      retryTime: 5,
      tryCount: 0,
      systemError: `System error. Please contact administrator`,
      loader_Messages: "",
      profilePicData: null,
      assistantNameData: null,
      methodsCalled: localStorage.getItem("methodsCalled")
        ? JSON.parse(localStorage.getItem("methodsCalled"))
        : {},
      botImages: localStorage.getItem("botImages")
        ? JSON.parse(localStorage.getItem("botImages"))
        : {},

      profileDataFetched: false,
    };
    this.arrowRef = React.createRef();
    this.containerRef = React.createRef();
  }
  handleViewInChat = (message) => {
    this.setState({ selectedBookmark: message });
  };
  async componentDidMount() {
    this.scrollToBottom();
    // if (!this.state.profileDataFetched) {
    //   await this.getProfileData();
    //   this.setState({ profileDataFetched: true });
    // }
    const methodCalled = { ...this.state.methodsCalled };
    if (!methodCalled["CSVgetProfileData"]) {
      await this.CSVgetProfileData();
    }
    const botImage = { ...this.state.botImages };
    if(botImage.profilePicData || botImage.assistantNameData ){
      this.setState(
        {
          profilePicData: botImage["profilePicData"],
          assistantNameData: botImage["assistantNameData"],
        },
        () => {
          // console.log("profilePicData",this.state.profilePicData);
          // console.log("assistantNameData",this.state.assistantNameData);
        }
      );
    }
   
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.messages.length !== prevState.messages.length) {
      this.scrollToBottom();
      this.setState({ timeOutMsg: "" });
    }
  }
  CSVgetProfileData = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email")

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
       `${BASE_URL}/admin/customize_prompts_chat_ui/${userOrganization}?applicationType=${encodeURIComponent(
        "zunofordata"
      )}&email=${encodeURIComponent(email)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        if (!result || result.message){
          this.setState(
            (prevState) => ({
              botImages: {
                ...prevState.botImages,
                profilePicData: null,
                assistantNameData: "Zuno",
              },
              profilePicData: null,
              assistantNameData: "Zuno",
            }),
            () => {
              // console.log("profilePicData", this.state.profilePicData);
              // console.log("botImages", this.state.botImages);
              localStorage.setItem(
                "botImages",
                JSON.stringify(this.state.botImages)
              );
            }
          );
        }
        else {
          this.setState(
            (prevState) => ({
              botImages: {
                ...prevState.botImages,
                profilePicData: result.profilePic,
                assistantNameData: result.assistantName
                  ? result.assistantName
                  : "Zuno",
              },
              profilePicData: result.profilePic,
              assistantNameData: result.assistantName
                ? result.assistantName
                : "Zuno",
            }),
            () => {
              // console.log("profilePicData", this.state.profilePicData);
              // console.log("botImages", this.state.botImages);
              localStorage.setItem(
                "botImages",
                JSON.stringify(this.state.botImages)
              );
            }
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    this.setState(
      (prevState) => ({
        methodsCalled: { ...prevState.methodsCalled, CSVgetProfileData: true },
      }),
      () => {
        localStorage.setItem(
          "methodsCalled",
          JSON.stringify(this.state.methodsCalled)
        );
      }
    );
  };

  scrollToBottom() {
    const container = this.containerRef.current;
    container.scrollTop = container.scrollHeight;
  }

  handleInputChange = (event) => {
    event.preventDefault();
    this.setState({ newMessage: event.target.value });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13 && event.shiftKey) {
      // Prevent Enter with Shift from creating a new line
      event.preventDefault();

      // Move the cursor to the next line
      const cursorPos = event.target.selectionStart;
      const inputValue = this.state.newMessage;
      const newMessage =
        inputValue.substring(0, cursorPos) +
        "\n" +
        inputValue.substring(cursorPos);

      this.setState({ newMessage });
    } else if (event.keyCode === 13) {
      // Prevent Enter from submitting the form
      event.preventDefault();

      // Handle sending the message

      if (!this.state.loader) {
        this.handleSendButton(event);
      }
    }
  };

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  handleRetry = async (index) => {
    let { messages } = this.state;

    messages = messages.slice(0, messages.length - 2);

    localStorage.setItem("messages", JSON.stringify(messages));

    this.setState(
      (prevState) => ({
        newMessage: prevState.prevQuestion,
        messages: messages,
        tryCount: prevState.tryCount + 1,
      }),
      () => {
        //this.handleLoadAndSendMessage("retry")
        // console.log("newMessage after retry", this.state.newMessage);
        // console.log("prevQuestion after retry", this.state.prevQuestion);
        // console.log("messages", this.state.messages);
        // console.log("try count", this.state.tryCount);
        this.handleLoadAndSendMessage();
      }
    );
  };

  handleSendButton = async () => {
    const { newMessage, messages, prevQuestion } = this.state;
    let error = "Connection timed out. Please try again";
    let text;
    if (messages.length >= 1) {
      text = messages[messages.length - 1].text
        ? messages[messages.length - 1].text
        : null;
    }

    if (newMessage === prevQuestion && error === text) {
      this.handleRetry();
    } else {
      this.setState((prevState) => ({ tryCount: (prevState.tryCount = 0) }));
      this.handleLoadAndSendMessage();
    }
  };

  handleLoadAndSendMessage = async (event) => {
    this.setState({
      loader: true,
      loader_Messages: "We're analyzing your data! ",
    });

    await this.handleSendMessage(event);
  };

  handleSendMessage = async (event) => {
    // event.preventDefault();
    // const querydate = new Date().toLocaleTimeString([], {
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    // });
    const { messages, newMessage } = this.state;

    // const time = new Date().toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const date = new Date();
    const timestamp = `${date.toLocaleDateString("en-US")} ${date
      .toLocaleTimeString("en-US")
      .slice(0, -3)}`;
    console.log(timestamp);
    let username = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    //  const promptTemplateText = localStorage.getItem("promptTemplateText");
    const userDetails = localStorage.getItem("currentLoggedInUserDetails");
    const userDetailsString = JSON.parse(userDetails);

    const organization = userDetailsString.organization;
    const department = userDetailsString.department;
    let text; // Define text variable here

    let count = 0;
    const loaderMessage = setInterval(() => {
      let messages = [
        "We're analyzing your data! ",
        "Extracting key information... ",
        "Unveiling insights from your data.",
        "Putting the pieces together to answer your question.",
        "Nearly there! Just a few more moments.",
        "Formulating the perfect response for you.",
        "Double-checking to ensure accuracy. ",
        "Getting close! You'll have your answer soon.",
        "Working on it...",
      ];

      this.setState({
        loader_Messages: messages[count],
      });
      count++;
    }, 30 * 1000);
    // Use the retrieved values as needed
    if (newMessage.trim() === "") {
      return;
    }
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("username", username);
    formData.append("email", email);

    const updatedMessages = [
      ...messages,
      { sender: username, text: newMessage, timestamp },
    ];
    this.setState({ messages: updatedMessages, newMessage: "" });

    this.fetchController.abort();
    this.fetchController = new AbortController();

    const fetchTimeOut = setTimeout(() => {
      this.fetchController.abort();
    }, 4 * 60 * 1000);

    try {
      let endpoint = `${BASE_URL}/zn001/bot/post_csv_message`;
      let applicationType = "CSV";

      if (this.props.is_sql) {
        endpoint = `${BASE_URL}/zn001/bot/post_message`;
        applicationType = "Databases";
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        signal: this.fetchController.signal,
      });

      clearTimeout(fetchTimeOut);
      const result = await response.json();
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }

      let receivedMessages;
      if (applicationType === "Databases") {
        if (result.is_multi_db_chain) {
          if (result.column_names && result.table_data && result.chart_data) {
            receivedMessages = [
              ...updatedMessages,
              {
                sender: result.sender,
                text: result.message,
                timestamp,
                SQL: result.sql_query,
                token_consumed: result.token_consumed,
                token_cost: result.token_cost,
                col_names: result.column_names,
                rows: result.table_data,
                chartdata: result.chart_data,
              },
            ];
            text = result.message;
          } else {
            console.log(result);
            receivedMessages = [
              ...updatedMessages,
              {
                sender: result.sender,
                text: result.message,
                timestamp,
                SQL: result.sql_query,
                token_consumed: result.token_consumed,
                token_cost: result.token_cost,
              },
            ];
            text = result.message;
          }
        } else if (result.message.intermediate_steps) {
          // console.log(result);
          if (result.column_names && result.table_data && result.chart_data) {
            receivedMessages = [
              ...updatedMessages,
              {
                sender: result.sender,
                text: result.message.result,
                timestamp,
                SQL: result.message.intermediate_steps[2].sql_cmd,
                col_names: result.column_names,
                rows: result.table_data,
                chartdata: result.chart_data,
                token_consumed: result.token_consumed,
                token_cost: result.token_cost,
              },
            ];
            text = result.message.result;
          } else {
            // console.log(result);
            receivedMessages = [
              ...updatedMessages,
              {
                sender: result.sender,
                text: result.message.result,
                timestamp,
                SQL: result.sql_query,
                token_consumed: result.token_consumed,
                token_cost: result.token_cost,
              },
            ];
            text = result.message.result;
          }
        } else {
          // console.log(result);
          receivedMessages = [
            ...updatedMessages,
            {
              sender: result.sender,
              text: result.message,
              timestamp,
              token_consumed: result.token_consumed,
              token_cost: result.token_cost,
            },
          ];
          text = result.message;
        }
        // console.log(receivedMessages);
        localStorage.setItem("messages", JSON.stringify(receivedMessages));
        this.setState({
          loader: false,
          messages: receivedMessages,
          // newMessage: "",
        });

        // Call queryLogs with necessary parameters
        this.queryLogs(
          newMessage,
          text,
          date,
          applicationType,
          organization,
          department,
          token,
          result.token_consumed,
          result.token_cost
        );
      } else if (applicationType === "CSV") {
        if (result.message.intermediate_steps) {
          if (result.column_names && result.table_data && result.chart_data) {
            receivedMessages = [
              ...updatedMessages,
              {
                sender: result.sender,
                text: result.message.result,
                timestamp,
                SQL: result.message.intermediate_steps[2].sql_cmd,
                col_names: result.column_names,
                rows: result.table_data,
                chartdata: result.chart_data,
                token_consumed: result.token_consumed,
                token_cost: result.token_cost,
              },
            ];
            text = result.message.result;
          } else {
            receivedMessages = [
              ...updatedMessages,
              {
                sender: result.sender,
                text: result.message.result,
                timestamp,
                SQL: result.sql_query,
              },
            ];
            text = result.message.result;
          }
        } else {
          receivedMessages = [
            ...updatedMessages,
            {
              sender: result.sender,
              text: result.message,
              timestamp,
              token_consumed: result.token_consumed,
              token_cost: result.token_cost,
            },
          ];
          text = result.message;
        }
        localStorage.setItem("messages", JSON.stringify(receivedMessages));
        this.setState({
          loader: false,
          messages: receivedMessages,
          //newMessage: "",
        });
        this.queryLogs(
          newMessage,
          text,
          date,
          applicationType,
          organization,
          department,
          token,
          result.token_consumed,
          result.token_cost
        );
      }
    } catch (error) {
      for (let index = 6; index >= 0; index--) {
        let arr = [5, 5, 4, 3, 2, 1];
        setTimeout(() => {
          this.setState({ retryTime: arr[index] });
        }, index * 1000);
      }

      //IIFE
      (() => {
        this.setState({ timeOutMsg: "Please wait for while" }, () => {
          // console.log("time out message IIFE", this.state.timeOutMsg);
        });
      })();

      let receivedMessages;
      // let text;
      receivedMessages = [
        ...updatedMessages,
        {
          sender: "ZUNO",
          text: "Connection timed out. Please try again",
          timestamp,
          //token_consumed: result.token_consumed,
        },
      ];
      // text = this.state.newMessage;
      localStorage.setItem("messages", JSON.stringify(receivedMessages));
      this.setState({
        //loader: false,
        messages: receivedMessages,
        prevQuestion: newMessage,
        //newMessage: "",
      });
      console.error("Error in Query_logs:", error);
    } finally {
      this.setState({ loader: false });
      clearInterval(loaderMessage);
    }
  };

  queryLogs = async (
    newMessage,
    text,
    date,
    applicationType,
    organization,
    department,
    token,
    token_consumed,
    token_cost
  ) => {
    try {
      const queryLogsEndpoint = `${BASE_URL}/zn001/user/Query_logs`;
      const formData = new FormData();
      formData.append("query", newMessage);
      formData.append("response", text);
      formData.append("date", date);
      formData.append("application", applicationType);
      formData.append("organization", organization);
      formData.append("department", department);
      formData.append("credentials", token);
      formData.append("token_consumed", token_consumed);
      formData.append("token_cost", token_cost);
      const queryLogsResponse = await fetch(queryLogsEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const queryLogsResult = await queryLogsResponse.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  resetChat = () => {
    localStorage.setItem("messages", JSON.stringify([]));
    this.setState({
      messages: [],
    });
  };
  inputChange = (event) => {
    if (event.target.key === "Enter") {
      event.preventDefault();
    }
  };
  viewSQL = () => {
    const { viewQuery } = this.state;
    if (viewQuery) {
      this.setState({ viewQuery: false });
    } else {
      this.setState({ viewQuery: true });
    }
  };

  toggleBookmark = async (messageIndex, sender) => {
    try {
      // Step 1: Get the JWT token from localStorage

      // Step 2: Access the message to bookmark from this.state.messages
      const messageToBookmark = this.state.messages[messageIndex];
      const queryToBookmark = this.state.messages[messageIndex - 1];
      // Check if messageToBookmark is defined
      if (!messageToBookmark) {
        console.error(`Message at index ${messageIndex} is undefined`);
        return;
      }
      // if messae is already bookmarked
      if (messageToBookmark.bookmarked) {
        this.removeBookmark(messageToBookmark, messageIndex);
        this.handleSnackbarOpen("success", "Removed bookmark successfully");
      } else {
        this.createBookmark(messageToBookmark, queryToBookmark, messageIndex);
        this.handleSnackbarOpen("success", "Created bookmark successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  createBookmark = async (messageToBookmark, queryToBookmark, messageIndex) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("token not found in localStorage");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const userDetails = localStorage.getItem("currentLoggedInUserDetails");
      const userDetailsString = JSON.parse(userDetails);

      const organization = userDetailsString.organization;
      // Step 3: Prepare the bookmark URL
      const bookmarkURL = `${BASE_URL}/zn001/user/bookmark`;

      // Step 4: Send a POST request to toggle the message bookmark
      const response = await fetch(bookmarkURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify({ message: messageToBookmark }),
        body: JSON.stringify({
          organization: organization,
          userQuery: queryToBookmark.text,
          response: messageToBookmark.text,
          sqlQuery: messageToBookmark.SQL,
          tableData: {
            column_names: messageToBookmark.col_names,
            rows: messageToBookmark.rows,
          },
          chartData: messageToBookmark.chartdata,
          tokenConsumed: messageToBookmark.token_consumed,
          tokenCost: messageToBookmark.token_cost,
          timeStamp: messageToBookmark.timestamp,
        }),
      });

      // Step 4: Handle the response
      if (response.ok) {
        const responseData = await response.json();
        console.log("responseData", responseData);
        // Assuming the server responds with a bookmark ID
        const bookmarkId = responseData.bookmark_id;
        //   // Update the state to reflect the bookmarked message
        this.setState(
          (prevState) => {
            // Create a copy of messages array to avoid modifying state directly
            const updatedMessages = [...prevState.messages];

            updatedMessages[messageIndex] = {
              ...messageToBookmark,
              bookmarked: true, // Update the bookmarked status
              bookmarkID: bookmarkId,
            };

            return { messages: updatedMessages };
          },
          () => {
            // Save the updated messages array to local storage after setState
            localStorage.setItem(
              "messages",
              JSON.stringify(this.state.messages)
            );
          }
        );
      } else {
        console.error("Failed to toggle message bookmark");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  removeBookmark = async (messageToBookmark, messageIndex) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("token not found in localStorage");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const userDetails = localStorage.getItem("currentLoggedInUserDetails");
      const userDetailsString = JSON.parse(userDetails);

      const organization = userDetailsString.organization;
      const response = await fetch(
        `${BASE_URL}/zn001/user/remove_bookmark/${
          messageToBookmark.bookmarkID
        }?userOrganization=${encodeURIComponent(organization)}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        this.setState(
          (prevState) => {
            // Create a copy of messages array to avoid modifying state directly
            const updatedMessages = [...prevState.messages];

            updatedMessages[messageIndex] = {
              ...messageToBookmark,
              bookmarked: false, // Update the bookmarked status
              bookmarkID: null,
            };

            return { messages: updatedMessages };
          },
          () => {
            // Save the updated messages array to local storage after setState
            localStorage.setItem(
              "messages",
              JSON.stringify(this.state.messages)
            );
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  toggleChartVisibility = () => {
    this.setState({
      isChartVisible: !this.state.isChartVisible,
      isTableVisible: false,
    });
  };

  // Method to toggle visibility of the Table
  toggleTableVisibility = () => {
    this.setState({
      isTableVisible: !this.state.isTableVisible,
      isChartVisible: false,
    });
  };

  isChartDataValid = (x_axis_data, y_axis_data) => {
    const isXStringArray =
      Array.isArray(x_axis_data) &&
      x_axis_data.every((item) => typeof item === "string");
    const isYStringArray =
      Array.isArray(y_axis_data) &&
      y_axis_data.every((item) => typeof item === "string");

    // Show the chart only when one is a string and the other is numeric
    return !(isXStringArray && isYStringArray);
  };

  getProfileData = async () => {
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/customize_prompts/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordata"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        // console.log("result",result);
        this.setState({
          profilePicData: result.profilePic,
          assistantNameData: result.assistantName
            ? result.assistantName
            : "Zuno",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  render() {
    // const { messages, newMessage, viewQuery, selectedBookmark,isTableVisible, isChartVisible } = this.state;
    const {
      messages,
      newMessage,
      viewQuery,
      timeOutMsg,
      retryTime,
      tryCount,
      systemError,
      loader_Messages,
      loader,
      isChartVisible,
      profilePicData,
      assistantNameData,
    } = this.state;

    return (
      <>
        <div
          ref={this.containerRef}
          style={{
            height: "calc(95vh - 200px)",
            overflow: "auto",
            padding: "10px 10px 10px 10px",
            marginTop: "5%",
            width: "95%",
            background: "#F3F6FD",
            borderRadius: "20px 20px 0px 0px",
            fontSize: "30px",
          }}
        >
          <HeaderText
            profilePicData={profilePicData}
            assistantNameData={assistantNameData}
          />

          <Grid container>
            <ChatMapData
              messages={messages}
              viewQuery={viewQuery}
              timeOutMsg={timeOutMsg}
              retryTime={retryTime}
              tryCount={tryCount}
              systemError={systemError}
              isChartVisible={isChartVisible}
              handleRetry={this.handleRetry}
              toggleBookmark={this.toggleBookmark}
              isChartDataValid={this.isChartDataValid}
              toggleChartVisibility={this.toggleChartVisibility}
              viewSQL={this.viewSQL}
              profilePicData={profilePicData}
            />

            {this.state.loader ? (
              <>
                <LoaderMessages loader_Messages={loader_Messages} />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </div>

        <div
          style={{
            height: "100px",
            overflow: "auto",
            padding: "10px 10px 10px 10px",
            width: "95%",
            background: "#F3F6FD",
            borderRadius: "0px 0px 20px 20px ",
            fontSize: "30px",
          }}
        >
          <ChatInputBase
            newMessage={newMessage}
            loader={loader}
            handleSendButton={this.handleSendButton}
            handleInputChange={this.handleInputChange}
            handleKeyDown={this.handleKeyDown}
          />

          <Snackbar
            open={this.state.showSnackbar}
            autoHideDuration={3000}
            onClose={this.handleSnackbarClose}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.handleSnackbarClose}
              severity={this.state.snackbarSeverity}
            >
              {this.state.snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </div>
      </>
    );
  }
}

export default withRouter(ChatUI);
