import React, { Component } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Botimageicon from "./botimageicon";
// import AvatarProfile from './AvatarProfile';
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, Tooltip, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
    "& .MuiInputBase-input": {
      padding: "8px", // Adjust the padding to reduce the height
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenuItem": {
    maxHeight: "70px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
  "& .MuiMenuItem-root": {
    padding: "4px 10px", // Adjust the padding to reduce the height
  },
};
const BASE_URL = process.env.REACT_APP_BASE_URL;

export class HeaderText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectInfo: [],
      selected: "",
      summary: "",
      filenameAndSummaryData: {},
      showLoader: false,
      databasenameAndSummary: {},
      isDatabaseSummary: true,
    };
  }
  componentDidMount() {
    const filenamesAndSummary = localStorage.getItem("filenameAndSummaryData")
      ? JSON.parse(localStorage.getItem("filenameAndSummaryData"))
      : {};
    const filenames = Object.keys(filenamesAndSummary);
    const firstSummary = Object.values(filenamesAndSummary);

    if (filenames.length > 0) {
      this.setState(
        {
          filenameAndSummaryData: filenamesAndSummary,
          selectInfo: filenames,
          selected: filenames[0],
          summary: firstSummary[0],
          isDatabaseSummary: false,
        },
        () => {
          // console.log("selectInfo", this.state.selectInfo);
        }
      );
      return;
    }
    const databasenamesAndSummary = localStorage.getItem(
      "databasenameAndSummary"
    )
      ? JSON.parse(localStorage.getItem("databasenameAndSummary"))
      : {};
    let databasename = databasenamesAndSummary["databasename"];
    const databaseSummary = databasenamesAndSummary["table_summary"];

    if (localStorage.getItem("databasenameAndSummary")) {
      this.setState(
        (prevState) => ({
          selectInfo: [...prevState.selectInfo, databasename],
          selected: databasename,
          summary: databaseSummary,
          isDatabaseSummary: true,
        }),
        () => {
          // console.log("selectInfo2", this.state.selectInfo);
        }
      );
    }
  }
  handleDropDown = (filename) => {
    const updatedFilenameAndSummary = { ...this.state.filenameAndSummaryData };
    const csvSummary = updatedFilenameAndSummary[filename];
    this.setState({ selected: filename, summary: csvSummary });
  };
  generateSummary = async (selected) => {
    const filename = selected;
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      // Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    try {
      // Show the loader while the update is in progress
      this.setState({ showLoader: true });
      const response = await fetch(
        `${BASE_URL}/zn001/bot/get_file_summary/${filename}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        // Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        const updatedFilenameAndSummary = {
          ...this.state.filenameAndSummaryData,
        };
        updatedFilenameAndSummary[filename] = responseData.file_summary;
        this.setState(
          {
            summary: responseData.file_summary,
            filenameAndSummaryData: updatedFilenameAndSummary,
          },
          () => {
            localStorage.setItem(
              "filenameAndSummaryData",
              JSON.stringify(this.state.filenameAndSummaryData)
            );
          }
        );
      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  render() {
    const { selectInfo, selected, summary, showLoader, isDatabaseSummary } =
      this.state;

    const { profilePicData, assistantNameData } = this.props;
    return (
      <Box
        className={`ZUNO`}
        style={{
          background: "#FFFFFF",
          borderRadius: "40px",
          padding: "10px 10px 10px 10px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "20px",
        }}
      >
        {/* <ListItem>
          <ListItemAvatar></ListItemAvatar>
        </ListItem> */}
        <ListItem>
          <ListItemAvatar sx={{ marginRight: "1%" }}>
            {profilePicData ? (
              <Stack direction="row" spacing={2} sx={{ marginRight: "5px" }}>
                <Avatar
                  alt="profilePic"
                  src={profilePicData}
                  sx={{ width: 56, height: 56 }}
                />
              </Stack>
            ) : (
              <Botimageicon />
            )}
          </ListItemAvatar>
          <Box style={{ fontSize: "1.3rem", color: "#818589" }}>
            <p>
              Hello! I'm {assistantNameData}, your smart and helpful data
              analyst. I'm here to assist you in extracting valuable insights
              and data from databases and CSV files.
            </p>
            {selectInfo.length > 0 && (
              <Box style={{ display: "flex", alignItems: "center" }}>
              
                 
                  <Tooltip title={selected} arrow placement="top">
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "1.3rem",
                    fontFamily: "Poppins, sans-serif",
                    color: "#6b6f73",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  {isDatabaseSummary
                    ? "Selected Database:"
                    : "Selected File:"}
                </Typography>
                {isDatabaseSummary ?<p style={{marginTop:"5px",marginBottom:"10px",padding:0}}>{selected}</p>  : null}

                  { !isDatabaseSummary && <TextField
                      sx={{
                        color: "#6001FF",
                        width: "175px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F3F6FD",
                        paddingBottom: "2px",
                        marginRight: "10px",
                        marginTop:"5px",
                        marginBottom:"10px",
                        ...textFieldSx,
                      }}
                      // label={isDatabaseSummary ? "Database Name" : "CSV Files"}
                      variant="outlined"
                      value={selected}
                      onChange={(e) => this.handleDropDown(e.target.value)}
                      select
                      fullWidth
                      InputProps={{
                        sx: {
                          height: "40px", // Adjust the height of the TextField
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          lineHeight: "0.77", // Adjust the line height of the label
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          PaperProps: {
                            style: {
                              maxHeight: "200px",
                              overflowY: "auto",

                              marginLeft: "2px",
                            },
                          },
                        },
                      }}
                    >
                      {selectInfo.map((item) => {
                        return (
                          <MenuItem
                            sx={{ ...selectFieldSx, }}
                            value={item}
                            key={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>}
                  </Tooltip>
                
                
                {!summary && (
                  <Button
                    onClick={() => this.generateSummary(selected)}
                    style={{
                      textTransform: "none",
                      color: "#FFF",
                      backgroundColor: "#6001FF",
                      maxHeight: "38px",
                      marginLeft: "20px",
                      marginTop:"35px",
                    }}
                  >
                    {showLoader ? (
                      <CircularProgress
                        size={25}
                        sx={{
                          color: "#FFFFFF",
                          marginLeft: "-7%",
                        }}
                      />
                    ) : (
                      "Generate"
                    )}
                  </Button>
                )}
              </Box>
            )}

            {selectInfo.length > 0 && (
              <span style={{ marginTop: "10px" }}>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "1.3rem",
                    fontFamily: "Poppins, sans-serif",
                    color: "#6b6f73",
                    fontWeight: "bold",
                  }}
                >
                  Summary
                </Typography>

                {summary}
              </span>
            )}
            <Typography
              variant="h6"
              style={{
                fontSize: "1.3rem",
                fontFamily: "Poppins, sans-serif",
                color: "#6b6f73",
                fontWeight: "bold",
              }}
            >
              <p>{""}</p>
              Not sure where to start? You can try:
            </Typography>
            <ul
              style={{ margin: "0", padding: "0", listStylePosition: "inside" }}
            >
              <li>What were the ecommerce sales revenues for last month?</li>
              <li>Which are the top 10 cities in terms of sales volume? </li>
              <li>Which campaigns have the highest response rates?</li>
            </ul>
          </Box>
        </ListItem>
      </Box>
    );
  }
}

export default HeaderText;
